var $modal = document.getElementById("js-modal"),
  $inner = $modal.querySelector("div"),
  $close = $modal.querySelector("button"),
  $openArray = [...document.querySelectorAll(".js-modal_open")];

$openArray.forEach($open => {
  $open.addEventListener("click", e => {
    e.preventDefault();
    const src = `${e.currentTarget.href}?autoplay=1`;
    $inner.innerHTML = `<iframe src="${src}" frameborder="0" allowfullscreen></iframe>`;
    $modal.classList.add("-active");
  });
});

$close.addEventListener("click", () => {
  $modal.classList.remove("-active");
});

$modal.addEventListener("transitionend", e => {
  if ($modal.classList.contains("-active")) return;
  $inner.innerHTML = "";
});
